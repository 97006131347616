// assets
import config from '../config';
import { ReactComponent as BonsaiExchange } from 'assets/images/icons/BonsaiExchange.svg';
import { ReactComponent as FinancialTool } from 'assets/images/icons/FinancialTool.svg';
import { ReactComponent as FormSubmission } from 'assets/images/icons/FormSubmission.svg';
import { ReactComponent as Settings } from 'assets/images/icons/Settings.svg';
import { ReactComponent as ClientManagement } from 'assets/images/icons/ClientManagement.svg';
import { ReactComponent as MyAccount } from 'assets/images/icons/MyAccount.svg';
import { ReactComponent as Support } from 'assets/images/support.svg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const {
  ROLES: { CLIENT, ADVISOR, USER, ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, SUPER_ADMIN },
  SUBSCRIPTIONS: { STANDARD },
  EXTERNAL_URLS: { TERM_LIFE_INSURANCE, ANNUITIES, BUSINESS_VALUATIONS, CONTACT_SUPPORT, TRAVEL_ASSISTANCE, DISABILITY_INSURANCE }
} = config;

const role = localStorage.getItem('Role');
const financialEnabled = localStorage.getItem('Status') == 'true';
const subscription = localStorage.getItem('Subscription');

export const Pages = {
  BonsaiExchange: {
    id: 'bonsai_exchange',
    title: 'Bonsai Exchange',
    type: 'collapse',
    url: '/bonsai_exchange',
    target: true,
    icon: BonsaiExchange,
    clientPortal: true,
    children: [
      {
        id: 'annuities',
        title: 'Annuities',
        type: 'item',
        url: ANNUITIES,
        target: true
      },

      {
        id: 'business_valuations',
        title: 'Business Valuations',
        type: 'item',
        url: BUSINESS_VALUATIONS,
        target: true
      },
      {
        id: 'disability_insurance',
        title: 'Disability Insurance',
        type: 'item',
        url: DISABILITY_INSURANCE,
        target: true
      },
      {
        id: 'life_insurance',
        title: 'Life Insurance',
        type: 'collapse',
        url: '/bonsai_exchange/life_insurance',
        target: true,
        childOpen: false,
        breadcrumbs: false,
        children: [
          {
            id: 'permanent_life_insurance',
            title: 'Permanent',
            type: 'item',
            url: '/bonsai_exchange/life_insurance/permanent_life_insurance',
            breadcrumbs: false
          },
          {
            id: 'term_life_insurance',
            title: 'Term',
            type: 'item',
            url: TERM_LIFE_INSURANCE,
            target: true
          },
          {
            id: 'variable_universal_life',
            title: 'Variable Universal Life',
            type: 'item',
            url: '/bonsai_exchange/life_insurance/variable_universal_life'
          }
        ]
      },
      {
        id: 'long_term_care',
        title: 'Long Term Care Insurance',
        type: 'item',
        url: '/bonsai_exchange/long_term_care',
        breadcrumbs: false
      },

      {
        id: 'medicare',
        title: 'Medicare supplement',
        type: 'item',
        url: '/bonsai_exchange/medicare',
        breadcrumbs: false
      },
      {
        id: 'private_placement',
        title: 'Private Placement',
        type: 'collapse',
        url: '/bonsai_exchange/private_placement',
        target: true,
        childOpen: false,
        breadcrumbs: false,
        children: [
          {
            id: 'private_placement_life_insurance',
            title: 'Life Insurance',
            type: 'item',
            url: '/bonsai_exchange/private_placement/life_insurance',
            breadcrumbs: false
          },
          {
            id: 'private_placement_variable_annuity',
            title: 'Variable Annuity',
            type: 'item',
            url: '/bonsai_exchange/private_placement/variable_annuity',
            breadcrumbs: false
          }
        ]
      },
      {
        id: 'travel_assistance',
        title: 'Travel Assistance',
        type: 'item',
        url: TRAVEL_ASSISTANCE,
        target: true
      }
    ]
  },
  FinancialPlanning: {
    id: 'financial_planning',
    title: 'Financial Planning Tool',
    type: 'item',
    url: '/financial_planning',
    icon: FinancialTool,
    breadcrumbs: false
  },
  ClientManagement: {
    id: 'client_management',
    title: 'Client Management',
    type: 'item',
    url: '/client_management',
    icon: ClientManagement,
    breadcrumbs: false
  },
  UserManagement: {
    id: 'user_management',
    title: 'User Management',
    type: 'item',
    url: '/user_management',
    icon: MyAccount,
    breadcrumbs: false
  },
  MyAccount: {
    id: 'my_account',
    title: 'My Account',
    type: 'item',
    url: '/my_account',
    icon: MyAccount,
    breadcrumbs: false
  },
  Settings: {
    id: 'Settings',
    title: 'Settings',
    type: 'item',
    url: '/Settings',
    icon: Settings,
    breadcrumbs: false,
    disabled: true
  },
  Support: {
    id: 'Support',
    title: 'Support',
    type: 'item',
    icon: Support,
    url: CONTACT_SUPPORT,
    target: true
  },
  FormSubmission: {
    id: 'form_submission',
    title: 'Form Submission',
    type: 'collapse',
    url: '/form_submission',
    target: true,
    childOpen: true,
    icon: FormSubmission,
    children: [
      {
        id: 'life_insurance',
        title: 'Life Insurance',
        type: 'item',
        url: '/form_submission/life_insurance',
        breadcrumbs: false
      },
      {
        id: 'long_term_care',
        title: 'Long Term Care',
        type: 'item',
        url: '/form_submission/long_term_care',
        breadcrumbs: false
      }
    ]
  },
  LifeInsuranceFormSubmission: {
    id: 'form_submission',
    title: 'Form Submission',
    type: 'collapse',
    url: '/form_submission',
    target: true,
    childOpen: true,
    icon: BonsaiExchange,
    children: [
      {
        id: 'life_insurance',
        title: 'Life Insurance',
        type: 'item',
        url: '/form_submission/life_insurance',
        breadcrumbs: false
      }
    ]
  },
  LongTermCareFormSubmission: {
    id: 'form_submission',
    title: 'Form Submission',
    type: 'collapse',
    url: '/form_submission',
    target: true,
    childOpen: true,
    icon: BonsaiExchange,
    children: [
      {
        id: 'long_term_care',
        title: 'Long Term Care',
        type: 'item',
        url: '/form_submission/long_term_care',
        breadcrumbs: false
      }
    ]
  },
  Tenants: {
    id: 'tenants',
    title: 'Tenants',
    type: 'item',
    url: '/tenants',
    icon: PeopleAltIcon,
    breadcrumbs: false,
    iconProps: {
      sx: {
        width: 30,
        height: 30,
        fillOpacity: '0.6'
      }
    }
  }
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const checkPremium = (...pages) => {
  return subscription != STANDARD ? [...pages] : [];
};

const pages = {
  id: 'pages',
  type: 'group',
  children:
    role === CLIENT
      ? financialEnabled
        ? [Pages.FinancialPlanning, Pages.MyAccount, Pages.Support]
        : [Pages.MyAccount, Pages.Support]
      : role === ADVISOR
        ? [Pages.BonsaiExchange, ...checkPremium(Pages.FinancialPlanning, Pages.ClientManagement), Pages.MyAccount, Pages.Support]
        : role === ADMIN || role === SUPER_ADMIN
          ? [Pages.BonsaiExchange, Pages.FormSubmission, Pages.UserManagement, Pages.Tenants, Pages.MyAccount, Pages.Support]
          : role === ENTERPRISE_ADMIN
            ? [Pages.BonsaiExchange, Pages.FormSubmission, Pages.UserManagement, Pages.MyAccount, Pages.Support]
            : role === USER
              ? [Pages.BonsaiExchange, Pages.MyAccount, Pages.Support]
              : role === LTC_MANAGER
                ? [Pages.BonsaiExchange, Pages.LongTermCareFormSubmission, Pages.MyAccount, Pages.Support]
                : role === LI_MANAGER
                  ? [Pages.BonsaiExchange, Pages.LifeInsuranceFormSubmission, Pages.MyAccount, Pages.Support]
                  : [Pages.BonsaiExchange, Pages.MyAccount, Pages.Support]
};

export default pages;
