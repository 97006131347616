const FE_BASE_URL = process.env.REACT_APP_FE_BASE_URL;
const FE_MAIN_URL = process.env.REACT_APP_MAIN_URL;
const config = {
  // App Defaults
  BASENAME: '/',
  DEFAULT_PATH: '/',
  FONT_FAMILY: 'Neue Montreal',
  BORDER_RADIUS: '5px',

  // Business Defaults
  ROLES: {
    SUPER_ADMIN: 'super_admin',
    CLIENT: 'client',
    USER: 'user',
    ADVISOR: 'advisor',
    ADMIN: 'admin',
    LI_MANAGER: 'life_insurance_manager',
    LTC_MANAGER: 'long_term_care_manager',
    ENTERPRISE_ADMIN: 'enterprise_admin'
  },
  SUBSCRIPTIONS: {
    STANDARD: 'Standard',
    PREMIUM: 'Premium',
    ENTERPRISE: 'Enterprise'
  },

  // Environment
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  EXTERNAL_URLS: {
    TERM_LIFE_INSURANCE: process.env.REACT_APP_BONSAI_EXCHANGE_TERM_LIFE_INSURANCE,
    ANNUITIES: process.env.REACT_APP_BONSAI_EXCHANGE_ANNUITIES,
    BUSINESS_VALUATIONS: process.env.REACT_APP_BONSAI_EXCHANGE_BUSINESS_VALUATIONS,
    CONTACT_SUPPORT: FE_MAIN_URL + process.env.REACT_APP_BONSAI_EXCHANGE_CONTACT_SUPPORT,
    CALENDLY_URL: process.env.REACT_APP_CALENDLY_URL,
    TRAVEL_ASSISTANCE: process.env.REACT_APP_BONSAI_EXCHANGE_TRAVEL_ASSISTANCE,
    DISABILITY_INSURANCE: process.env.REACT_APP_BONSAI_EXCHANGE_DISABILITY_INSURANCE,
    VIDEO_URL: process.env.REACT_APP_VIDEO_URL,
    LOGIN_URL: FE_BASE_URL + process.env.REACT_APP_LOGIN_URL,
    MICROSOFT_LOGIN_URL: FE_BASE_URL + process.env.REACT_APP_MICROSOFT_LOGIN_URL,
    REGISTER_URL: FE_BASE_URL + process.env.REACT_APP_REGISTER_URL,
    LOGOUT_URL: FE_BASE_URL + process.env.REACT_APP_LOGOUT_URL
  },
  THIRD_PARTY: {
    ZIP_CODE_API_URL: process.env.REACT_APP_ZIP_CODE_API_URL,
    ZIP_CODE_API_KEY: process.env.REACT_APP_ZIP_CODE_API_KEY
  }
};

export default config;
