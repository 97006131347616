import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { ProtectedRoute } from './protectedRoute';
import { isLogin } from 'store/Authentication/action';
import { Navigate } from 'react-router-dom';
import config from '../config';
import pages from 'menu-items/pages';
// import pages from 'menu-items/pages';

const {
  ROLES: { CLIENT, ADVISOR, ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, SUPER_ADMIN },
  SUBSCRIPTIONS: { PREMIUM, ENTERPRISE }
} = config;

// ==============================|| MAIN ROUTING ||============================== //
const FinancialTool = Loadable(lazy(() => import('views/pages/financialTool')));
const MyAccount = Loadable(lazy(() => import('views/pages/MyAccount')));
const UserManagement = Loadable(
  lazy(() => import('views/pages/UserManagement')),
  {
    hasFallback: false
  }
);
// const ClientManagement = Loadable(lazy(() => import('views/pages/clientManagement')));
const HouseHoldManagement = Loadable(lazy(() => import('views/pages/houseHold')));
const ViewClient = Loadable(lazy(() => import('views/pages/houseHold/viewClient')));
const EditClientForm = Loadable(lazy(() => import('views/pages/houseHold/editClient')));
const BonsaiExchange = Loadable(lazy(() => import('views/pages/bonsaiExchange')));
const LongTermCare = Loadable(lazy(() => import('views/pages/bonsaiExchange/longTermCare')));
const PPLI = Loadable(lazy(() => import('views/pages/bonsaiExchange/PPLI')));
const PPVA = Loadable(lazy(() => import('views/pages/bonsaiExchange/PPVA')));
const DisabilityInsurance = Loadable(lazy(() => import('views/pages/bonsaiExchange/DisabilityInsurance')));
const TravelAssistance = Loadable(lazy(() => import('views/pages/bonsaiExchange/TravelAssistance')));
const Medicare = Loadable(lazy(() => import('views/pages/bonsaiExchange/medicare')));
const LifeInsurance = Loadable(lazy(() => import('views/pages/bonsaiExchange/NewLifeInsurance')));
const TermLifeInsurance = Loadable(lazy(() => import('views/pages/bonsaiExchange/TermLifeInsurance')));
const FAQ = Loadable(lazy(() => import('views/pages/bonsaiExchange/lifeInsurance/Faq')));
const BookAppointment = Loadable(lazy(() => import('views/pages/bonsaiExchange/lifeInsurance/Appointment/form')));
const AdminPermanentLifeInsurance = Loadable(lazy(() => import('views/pages/formSubmission/permanentLifeInsurance')));
const AdminLongTermAndCare = Loadable(lazy(() => import('views/pages/formSubmission/longtermAndCare')));
const ErrorPage = Loadable(lazy(() => import('views/pages/ErrorPage')));
const Tenants = Loadable(
  lazy(() => import('views/pages/tenants')),
  {
    hasFallback: false
  }
);

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[CLIENT]} redirect={pages.children[0]?.url}>
          <></>
        </ProtectedRoute>
      )
    },
    {
      path: 'financial_planning',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[CLIENT, ADVISOR]} subscriptions={[PREMIUM, ENTERPRISE]} status={true}>
          <FinancialTool />
        </ProtectedRoute>
      )
    },
    {
      path: 'my_account',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()}>
          <MyAccount />
        </ProtectedRoute>
      )
    },
    {
      path: 'user_management',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADMIN, ENTERPRISE_ADMIN, SUPER_ADMIN]}>
          <UserManagement />
        </ProtectedRoute>
      )
    },
    {
      path: 'client_management',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADVISOR]} subscriptions={[PREMIUM, ENTERPRISE]}>
          <HouseHoldManagement />
        </ProtectedRoute>
      )
    },
    {
      path: 'client_details',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADVISOR]} subscriptions={[PREMIUM, ENTERPRISE]}>
          <ViewClient />
        </ProtectedRoute>
      )
    },
    {
      path: 'edit_client',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADVISOR]} subscriptions={[PREMIUM, ENTERPRISE]}>
          <EditClientForm />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]} redirect={pages.children[0]?.url}>
          <BonsaiExchange />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/private_placement/life_insurance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <PPLI />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/life_insurance/variable_universal_life',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <PPLI />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/private_placement/variable_annuity',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <PPVA />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/disability_insurance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <DisabilityInsurance />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/travel_assistance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <TravelAssistance />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/long_term_care',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <LongTermCare />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/medicare',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <Medicare />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/life_insurance/term_life_insurance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <TermLifeInsurance />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/life_insurance/permanent_life_insurance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <LifeInsurance />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/faq',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <FAQ />
        </ProtectedRoute>
      )
    },
    {
      path: 'bonsai_exchange/permanent_life_insurance/permanent_life_insurance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} notRoles={[CLIENT]}>
          <BookAppointment />
        </ProtectedRoute>
      )
    },
    {
      path: 'form_submission',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, SUPER_ADMIN]}>
          <Navigate to="/form_submission/life_insurance" replace={true} />
        </ProtectedRoute>
      )
    },
    {
      path: 'form_submission/life_insurance',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, SUPER_ADMIN]}>
          <AdminPermanentLifeInsurance />
        </ProtectedRoute>
      )
    },
    {
      path: 'form_submission/long_term_care',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADMIN, ENTERPRISE_ADMIN, LTC_MANAGER, SUPER_ADMIN]}>
          <AdminLongTermAndCare />
        </ProtectedRoute>
      )
    },
    {
      path: '*',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} noHeading>
          <ErrorPage notFound />
        </ProtectedRoute>
      )
    },
    {
      path: 'tenants',
      element: (
        <ProtectedRoute isAuthenticated={isLogin()} roles={[ADMIN, SUPER_ADMIN]}>
          <Tenants />
        </ProtectedRoute>
      )
    }
  ]
};

export default MainRoutes;
