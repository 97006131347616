import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// material-ui
import { Grid, Typography, Box, FormControl, FormGroup, FormControlLabel, Checkbox, Link } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { RHFDateField, RHFPhoneField, RHFSelect, RHFTextField } from 'ui-component/RHF';
import { States, FirmTypes } from 'utils/options';
import { yupPhone } from 'utils/phone-validate';
import { yupDate } from 'utils/date-validate';
import { useDispatch } from 'react-redux';
import { signup } from 'store/Authentication/action';

const validationSchemas = [
  Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    dob: yupDate(),
    residentState: Yup.string().required('Resident state is required'),
    workPhone: yupPhone('Work Phone number').required('Work Phone number is required'),
    mobilePhone: yupPhone('Mobile Phone number').required('Mobile Phone number is required'),
    firmName: Yup.string().required('Firm Name is required'),
    firmType: Yup.string().required('Firm Type is required'),
    referralCode: Yup.string(),
    finraCrd: Yup.string(),
    insuranceNpn: Yup.string()
  }),
  Yup.object({}),
  Yup.object({
    termsOfService: Yup.boolean().oneOf([true], 'You must agree to the terms of service').required('You must agree to the terms of service')
  })
];

const Signup = ({ setIsSignedUp, setTitle }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [licenseOptions, setLicenseOptions] = useState([
    { id: 'series_65', label: 'Series 65', isSelected: false },
    { id: 'series_6', label: 'Series 6', isSelected: false },
    { id: 'series_7', label: 'Series 7', isSelected: false },
    { id: 'Series_63', label: 'Series 63', isSelected: false },
    { id: 'state_life_insurance', label: 'State Life Insurance', isSelected: false },
    { id: 'cfp', label: 'CFP', isSelected: false },
    { id: 'cfa', label: 'CFA', isSelected: false },
    { id: 'cima', label: 'CIMA', isSelected: false },
    { id: 'chfc', label: 'ChFC', isSelected: false },
    { id: 'ricp', label: 'RICP', isSelected: false },
    { id: 'fic', label: 'FIC', isSelected: false },
    { id: 'other', label: 'Other', isSelected: false }
  ]);
  const methods = useForm({
    resolver: yupResolver(validationSchemas[step - 1])
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    if (step < 3) {
      setStep((prevStep) => prevStep + 1);
    } else {
      dispatch(signup({ ...values, licenseOptions, setIsSignedUp, setTitle, setLoading }));
    }
  };

  const handleLicenseChange = (id) => {
    setLicenseOptions((prevOptions) =>
      prevOptions.map((option) => (option.id === id ? { ...option, isSelected: !option.isSelected } : option))
    );
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <Typography variant="h2" mb={2}>
        Registration
      </Typography>
      <FormProvider {...methods}>
        <Box maxWidth="650px">
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {step === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: 'start' }}>
                  <FormControlLabel
                    control={<Checkbox name="isFinancialProfessional" />}
                    label={<Typography sx={{ fontSize: '1.125rem', lineHeight: '1.75rem' }}>I am a financial professional</Typography>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFTextField name="firstName" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFTextField name="lastName" required />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="email" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFDateField name="dob" label="Date of Birth" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFSelect name="residentState" required options={States} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFPhoneField name="workPhone" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFPhoneField name="mobilePhone" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFTextField name="firmName" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFSelect name="firmType" required options={FirmTypes} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <RHFTextField name="referralCode" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <RHFTextField name="finraCrd" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <RHFTextField name="insuranceNpn" />
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ mt: 2, mb: 3, textAlign: 'center' }}>
                    Licenses
                  </Typography>
                  <FormControl component="fieldset" sx={{ mx: 1 }}>
                    <FormGroup>
                      <Grid container spacing={3}>
                        {licenseOptions.map((option) => (
                          <Grid item xs={6} sm={4} key={option.id}>
                            <FormControlLabel
                              sx={{ display: 'flex' }}
                              control={
                                <Checkbox
                                  style={{
                                    color: option.isSelected ? '#0B8A6E' : '#B9B9B9',
                                    padding: 0
                                  }}
                                  onChange={() => handleLicenseChange(option.id)}
                                  checked={option.isSelected}
                                  name={option.id}
                                />
                              }
                              label={<Typography sx={{ ml: 1, fontWeight: '700' }}>{option.label}</Typography>}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {step === 3 && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="termsOfService"
                        value={methods.watch('termsOfService')}
                        checked={methods.watch('termsOfService')}
                        onChange={(e) => {
                          methods.setValue('termsOfService', e.target.checked, { shouldValidate: true });
                        }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ mr: 1, fontSize: '20px' }}>By registering with Bonsai Exchange you agree to our</Typography>
                        <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                          Terms of Service
                        </Link>
                      </Box>
                    }
                  />
                  {methods.formState.errors.termsOfService && (
                    <Typography variant="body2" color="error">
                      {methods.formState.errors.termsOfService.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box mt={2} sx={{ display: 'flex', justifyContent: step > 1 ? 'space-between' : 'end' }}>
                {step > 1 && (
                  <Box
                    onClick={handleBack}
                    sx={{
                      width: '170px',
                      mr: 2,
                      background: 'transparent',
                      border: 'none',
                      color: '#0B8A6E',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <ArrowBackIcon />
                    <Typography variant="button" sx={{ ml: 1 }}>
                      Back
                    </Typography>
                  </Box>
                )}
                {step === 3 ? (
                  <LoadingButton
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ width: '170px' }}
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    endIcon={<ArrowForwardIcon />}
                    loading={loading}
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      width: '110px',
                      // background: 'transparent',
                      border: 'none',
                      // color: '#0B8A6E',
                      boxShadow: 'none'
                    }}
                  >
                    Next
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          </form>
        </Box>
      </FormProvider>
    </>
  );
};

export default Signup;
